$spacer: 5px

/***********************************************************************************************************/
/********_DEFAULT_******************************************************************************************/
/***********************************************************************************************************/

a
  color: inherit
  text-decoration: none
  -webkit-touch-callout : none     /* iOS Safari */
  -webkit-user-select   : none     /* Chrome/Safari/Opera */
  -moz-user-select      : none     /* Firefox */
  -ms-user-select       : none     /* Internet Explorer/Edge */

a>*
  cursor: pointer !important

/***********************************************************************************************************/
/********_SHADOW_*******************************************************************************************/
/***********************************************************************************************************/

.shadow
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1)


.shadow:hover
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)


.shadow.no-hover:hover
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)

/***********************************************************************************************************/
/********_SIZING_*******************************************************************************************/
/***********************************************************************************************************/

.w-100
  width: 100% !important

.w-50
  width: 50% !important

.w-max-content
  width: max-content !important
  max-width: 100%

.w-min-content
  width: min-content !important
  max-width: 100%

.min-w-250
  min-width: 250px !important

.h-100
  height: 100% !important

/***********************************************************************************************************/
/********_SPACING_******************************************************************************************/
/***********************************************************************************************************/

.m-auto
  margin: auto !important

.m-1
  margin: $spacer !important

.m-2
  margin: $spacer * 2 !important

.m-3
  margin: $spacer * 3 !important

.m-4
  margin: $spacer * 4 !important

.p-1
  padding: $spacer !important

.p-2
  padding: $spacer * 2 !important

.p-3
  padding: $spacer * 3 !important

.p-4
  padding: $spacer * 4 !important

.pt-0
  padding-top: 0 !important

.pt-1
  padding-top: $spacer !important

.pt-2
  padding-top: $spacer * 2 !important

.pt-3
  padding-top: $spacer * 3 !important

.pt-4
  padding-top: $spacer * 4 !important

.pr-1
  padding-right: $spacer !important

.pr-2
  padding-right: $spacer * 2 !important

.pr-3
  padding-right: $spacer * 3 !important

.pr-4
  padding-right: $spacer * 4 !important

.pb-1
  padding-bottom: $spacer !important

.pb-2
  padding-bottom: $spacer * 2 !important

.pb-3
  padding-bottom: $spacer * 3 !important

.pb-4
  padding-bottom: $spacer * 4 !important

.pl-1
  padding-left: $spacer !important

.pl-2
  padding-left: $spacer * 2 !important

.pl-3
  padding-left: $spacer * 3 !important

.pl-4
  padding-left: $spacer * 4 !important

.px-1
  padding-left: $spacer !important
  padding-right: $spacer !important

.px-2
  padding-left: $spacer * 2 !important
  padding-right: $spacer * 2 !important

.px-3
  padding-left: $spacer * 3 !important
  padding-right: $spacer * 3 !important

.px-4
  padding-left: $spacer * 4 !important
  padding-right: $spacer * 4 !important

.py-1
  padding-top: $spacer !important
  padding-bottom: $spacer !important

.py-2
  padding-top: $spacer * 2 !important
  padding-bottom: $spacer * 2 !important

.py-3
  padding-top: $spacer * 3 !important
  padding-bottom: $spacer * 3 !important

.py-4
  padding-top: $spacer * 4 !important
  padding-bottom: $spacer * 4 !important

.mt-1
  margin-top: $spacer !important

.mt-2
  margin-top: $spacer * 2 !important

.mt-3
  margin-top: $spacer * 3 !important

.mt-4
  margin-top: $spacer * 4 !important

.mr-1
  margin-right: $spacer !important

.mr-2
  margin-right: $spacer * 2 !important

.mr-3
  margin-right: $spacer * 3 !important

.mr-4
  margin-right: $spacer * 4 !important

.mb-1
  margin-bottom: $spacer !important

.mb-2
  margin-bottom: $spacer * 2 !important

.mb-3
  margin-bottom: $spacer * 3 !important

.mb-4
  margin-bottom: $spacer * 4 !important

.ml-1
  margin-left: $spacer !important

.ml-2
  margin-left: $spacer * 2 !important

.ml-3
  margin-left: $spacer * 3 !important

.ml-4
  margin-left: $spacer * 4 !important

.mx-1
  margin-left: $spacer !important
  margin-right: $spacer !important

.mx-2
  margin-left: $spacer * 2 !important
  margin-right: $spacer * 2 !important

.mx-3
  margin-left: $spacer * 3 !important
  margin-right: $spacer * 3 !important

.mx-4
  margin-left: $spacer * 4 !important
  margin-right: $spacer * 4 !important

.my-1
  margin-top: $spacer !important
  margin-bottom: $spacer !important

.my-2
  margin-top: $spacer * 2 !important
  margin-bottom: $spacer * 2 !important

.my-3
  margin-top: $spacer * 3 !important
  margin-bottom: $spacer * 3 !important

.my-4
  margin-top: $spacer * 4 !important
  margin-bottom: $spacer * 4 !important

.py-0
  padding-top: 0 !important
  padding-bottom: 0 !important

.p-0
  padding: 0 !important

.m-0
  margin: 0 !important

.mt-0
  margin-top: 0 !important

/***********************************************************************************************************/
/********_BORDER_*******************************************************************************************/
/***********************************************************************************************************/

$borderColor: rgba(75, 72, 72, 0.27)

.border
  border: 1px $borderColor solid

.border-top
  border-top: 1px $borderColor solid !important

.border-bottom
  border-bottom: 1px $borderColor solid

.border-right
  border-right: 1px $borderColor solid

.border-left
  border-right: 1px $borderColor solid

.border-x
  border:
    left: 1px $borderColor solid
    right: 1px $borderColor solid

.border-y
  border:
    top: 1px $borderColor solid
    bottom: 1px $borderColor solid

/***********************************************************************************************************/
/********_POSITION_*****************************************************************************************/
/***********************************************************************************************************/

.position-static
  position: static

.position-relative
  position: relative

.position-absolute
  position: absolute

.position-fixed
  position: fixed

.position-sticky
  position: sticky

/***********************************************************************************************************/
/********_DISPLAY_******************************************************************************************/
/***********************************************************************************************************/

.d-none
  display: none

.d-inline
  display: inline

.d-inline-block
  display: inline-block

.d-block
  display: block

.d-table
  display: table

.d-table-cell
  display: table-cell

.d-table-row
  display: table-row

.d-flex
  display: flex
  flex-wrap: wrap

.d-inline-flex
  display: inline-flex

/***********************************************************************************************************/
/********_FLEX_DIRECTION_***********************************************************************************/
/***********************************************************************************************************/

.flex-row
  flex-direction: row

.flex-row-reverse
  flex-direction: row-reverse

.flex-column
  flex-direction: column

.flex-column-reverse
  flex-direction: column-reverse

/***********************************************************************************************************/
/********_FLEX_JUSTIFY_*************************************************************************************/
/***********************************************************************************************************/

.justify-content-start
  justify-content: start

.justify-content-end
  justify-content: end

.justify-content-center
  justify-content: center

.justify-content-between
  justify-content: space-between

.justify-content-around
  justify-content: space-around

/***********************************************************************************************************/
/********_FLEX_ALIGN_***************************************************************************************/
/***********************************************************************************************************/

.align-items-start
  align-items: start

.align-items-end
  align-items: end

.align-items-center
  align-items: center

.align-items-baseline
  align-items: baseline

.align-items-stretch
  align-items: stretch

/***********************************************************************************************************/
/********_COLOR_********************************************************************************************/
/***********************************************************************************************************/

$dangerColor: #dc3545
$warningColor: #dcbc1d

.color-danger
  color: $dangerColor

.color-warning
  color: $warningColor

/***********************************************************************************************************/
/********_BG_COLOR_*****************************************************************************************/
/***********************************************************************************************************/

$whiteBg: #ffffff

.bg-white
  background-color: $whiteBg

/***********************************************************************************************************/
/********_CURSOR_*******************************************************************************************/
/***********************************************************************************************************/

.cursor-pointer
  cursor: pointer

.cursor-zoom-in
  cursor: zoom-in

/***********************************************************************************************************/
/********_TEXT_*********************************************************************************************/
/***********************************************************************************************************/

.t-center
  text-align: center

.t-right
  text-align: right

.t-capitalize
  text-transform: capitalize

/***********************************************************************************************************/
/********_FONT_*********************************************************************************************/
/***********************************************************************************************************/

.f-xx-small
  font-size: xx-small

.f-x-small
  font-size: x-small

.f-smaller
  font-size: smaller

.f-small
  font-size: small

.f-medium
  font-size: medium

.f-large
  font-size: large

.f-larger
  font-size: larger

.f-x-large
  font-size: x-large

.f-xx-large
  font-size: xx-large

.f-xxx-large
  font-size: xxx-large

.f-inherit
  font-size: inherit

/***********************************************************************************************************/
/********_IFRAME_*******************************************************************************************/
/***********************************************************************************************************/

iframe
  width: 100%
  height: 400px

/***********************************************************************************************************/
/********_POSITIONING_**************************************************************************************/
/***********************************************************************************************************/

.top-0
  top: 0

.left-0
  left: 0

.right-0
  right: 0

.bottom-0
  bottom: 0

/***********************************************************************************************************/
/********_BACKGROUND_***************************************************************************************/
/***********************************************************************************************************/

$lightGray: lightgray
$lightBlue: lightBlue

$opacities: 1, 2, 3, 4, 5, 6, 7, 8, 9

@each $opacity in $opacities
  .light-gray-#{$opacity}
    background-color: rgba($lightGray, calc($opacity / 10))
  .light-blue-#{$opacity}
    background-color: rgba($lightGray, calc($opacity / 10))


/***********************************************************************************************************/
/********_OVERFLOW_*****************************************************************************************/
/***********************************************************************************************************/

.overflow-hidden
  overflow: hidden

.overflow-auto
  overflow: auto

/***********************************************************************************************************/
/********_Z-INDEX_******************************************************************************************/
/***********************************************************************************************************/

$z-indexes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 99, 999, 9999

@each $z-index in $z-indexes
  .z-index-#{$z-index}
    z-index: $z-index