body
  overflow: unset

#root, .App
  height: 100%

.ant-collapse-header
  padding: 0 !important

.font-white
  color: white !important

.border-radius-10
  border-radius: 10px !important

.font-weight-normal
  font-weight: normal

.h-380
  height: 380px

.color-brown
  color: #a0753b !important

.o-cover
  object-fit: cover

.font-bold
  font-weight: bold

.font-size-24
  font-size: 24px

.shadow-0
  box-shadow: none


img.s-img-switch
  object-position: center
  object-fit: cover
  height: 100%
  width: 100%

.gallery-grid
  column-count: 4
  gap: 30px

  @media (max-width: 1024px)
    column-count: 3
  @media (max-width: 800px)
    column-count: 2
  @media (max-width: 600px)
    column-count: 1

  .gallery-grid-item
    margin: 0
    display: block
    break-inside: avoid
    cursor: pointer
    position: relative

    &:not(:first-child)
      margin-top: 30px
    @media (max-width: 1024px)
      margin-top: 30px
    img
      &.scale
        transform: scale(1.2)
        transform-origin: top center
      width: 100%
      user-select: none
      vertical-align: middle

.display-linebreak
  white-space: pre-line

button.ant-btn:not(.send-email) *
  font-size: revert !important
  line-height: 1.5714285714285714 !important

.ant-checkbox
  align-self: unset

.ant-input *,
.ant-input-number *
  font-size: 14px !important

.contact-form
  .ant-form-item-explain-error
    font-size: 14px !important
  input,
  textarea
    margin: 0 !important
    color: #757579 !important
  textarea::placeholder,
  input::placeholder
    color: #757579 !important

.send-email
  background-color: #cd9f7a
  color: #ffffff
  display: inline-block
  padding: 10px 25px
  margin-bottom: 25px
  margin-top: 10px
  margin-right: 5px
  outline: none
  line-height: 1 !important
  cursor: pointer
  border: none
  *
    text-transform: uppercase
    font-weight: 700 !important
    letter-spacing: 1px
    line-height: 1 !important
    font-size: 13px
    font-family: Montserrat, "Helvetica Neue", sans-serif !important


@media screen and (max-width: 1199px)
  .prague-header .prague-navigation
    display: block !important
    visibility: hidden
    overflow: hidden
    height: 0
    padding: 0
    transition: height 0.6s ease-in-out, visibility 0s 0.6s
    &.open
      visibility: visible
      height: 100vh
      transition: height 0.4s ease-in-out
    .pargue-navigation-wrapper
      padding: 40px 30px

.ql-editor p
  margin: inherit !important
  padding: inherit !important


.ant-image-preview-img
  width: 800px
  object-fit: cover
  max-width: 90%
  max-height: 100% !important

.ant-image-preview-switch-right
  right: calc(50% - 400px)

.ant-image-preview-switch-left
  left: calc(50% - 400px)

.ant-image-preview-switch-left,
.ant-image-preview-switch-right
  color: rgba(255, 255, 255, 0.85)
  background: rgba(0, 0, 0, 0.9)
  &:hover
    background: rgba(0, 0, 0, 0.6)

.ant-image-preview-close
  background: rgba(255, 255, 255, 0.9)
  color: black
  &:hover
    color: white

@media screen and (max-width: 800px)
  .ant-image-preview-switch-right
    right: 0

  .ant-image-preview-switch-left
    left: 0

.no-wrap
  flex-wrap: nowrap !important

.sip-image
  padding: 0 15px 30px 30px
  display: flex
  .tag

    position: absolute
    bottom: 0
    right: 45px
    z-index: 3
    padding: 15px 15px
    color: #272727
    text-transform: uppercase
    font-family: 'Oswald', sans-serif
    font-weight: 300
    letter-spacing: 5px
    font-size: 13px
    line-height: 1.5em
    text-align: center
    background: #f4f4f4
  img
    position: relative
    z-index: 2
    width: 75%
    transition: all 0.5s
    transform: scale(1.0)
    &:hover
      transform: scale(0.95)

  &:before
    content: ''
    position: absolute
    top: 30px
    left: 0
    right: 45px
    bottom: 0
    border: 1px solid #f4f4f4

.project-grid-wrapper.zoom-in
  & .project-grid-item-img-link
    height: auto !important
  & .project-grid-item-img
    transition: transform 1.2s
    &:hover
      transform: scale(1.2) !important


img.sip-catalog
  object-fit: cover
  object-position: left center


